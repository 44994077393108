import api from "./api";
import URLS from "../utils/urls.js";

export const getUserEulaResponse = () => {
  return api.get(URLS.BACKEND_API + `/get_eula_details/`);
};

export const getUserEulaResponseWithContextSwitch = contextSwitch => {
  return api.get(
    URLS.BACKEND_API + `/get_eula_details/?context_switch=${contextSwitch}`
  );
};

export const putUserEulaResponse = payload => {
  return api.put(URLS.BACKEND_API + `/user_eula_response/`, { ...payload });
};
