import { call, put, takeEvery } from "redux-saga/effects";
import * as permissionService from "../../services/permissionService";
import {
  GET_ENTITLEMENT_CATEGORIES_PENDING,
  GET_PERMISSIONS_LIST_PENDING,
  GET_EXISTING_EMAIL_LISTS_PENDING,
  CREATE_PERMISSIONS_PENDING,
  CREATE_POST_PERMISSIONS_ACTION_RESET_PENDING,
  DELETE_REMOVE_PERMISSIONS_PENDING,
  DELETE_REMOVE_PERMISSIONS_ACTION_RESET_PENDING,
  GET_ENROLLMENT_STATUS_PENDING,
  getEntitlementCategoriesFulfilled,
  getPermissionsListFulfilled,
  postPermissionsFulfilled,
  deletePermissionsFulfilled,
  getEntitlementCategoriesRejected,
  getPermissionsListRejected,
  deletePermissionsRejected,
  postPermissionsRejected,
  getPermissionsListPending,
  getExistingEmailListsFulfilled,
  getExistingEmailListsRejected,
  getEnrollmentStatusFulfilled,
  getEnrollmentStatusRejected
} from "../actions/permissionActions";

import getResourceError from "./helpers/getResourceError";
import { handleLoader } from "../actions/loaderAction";
import { CONST_VALUES } from "../../constants/constants";

export function* entitlementCategories(action) {
  try {
    const fetchedEntitlementCategories = yield call(
      permissionService.entitlementCategories
    );
    yield put(getEntitlementCategoriesFulfilled(fetchedEntitlementCategories));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ENTITLEMENT_CATEGORIES",
      "Oops. An error occurred when fetching the entitlement categories."
    );
    yield put(getEntitlementCategoriesRejected(resourceError));
  }
}

export function* permissionsList(action) {
  try {
    yield put(handleLoader(true));
    const fetchedPermissionsList = yield call(
      permissionService.permissionsList
    );
    if (permissionsList && permissionsList.message === CONST_VALUES.Error) {
      yield put(getPermissionsListFulfilled(null));
    } else {
      yield put(getPermissionsListFulfilled(fetchedPermissionsList));
    }
    yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "PERMISSIONS_LIST",
      "Oops. An error occurred when fetching the permissions list."
    );
    yield put(getPermissionsListRejected(resourceError));
  }
}

export function* existingEmailLists(action) {
  try {
    const { payload } = action.payload;
    const fetchedExistingEmailLists = yield call(
      permissionService.existingEmailLists,
      payload
    );
    yield put(getExistingEmailListsFulfilled(fetchedExistingEmailLists));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "EXISTING_EMAIL_LISTS",
      "Oops. An error occurred when fetching the existing users list."
    );
    yield put(getExistingEmailListsRejected(resourceError));
  }
}

export function* postPermissions(action) {
  const { payload } = action.payload;
  const { panelType } = action.payload;
  yield put(handleLoader(true));
  if (panelType === "add") {
    try {
      const response = yield call(permissionService.postPermissions, payload);
      yield put(postPermissionsFulfilled(response));
    } catch (error) {
      const resourceError = yield call(
        getResourceError,
        error,
        "POST",
        "PERMISSIONS",
        "Oops. An error occurred when updating the permissions."
      );
      yield put(postPermissionsRejected(resourceError));
    }
    yield put(handleLoader(false));
  } else if (panelType === "edit") {
    try {
      const response = yield call(permissionService.putPermissions, payload);
      yield put(postPermissionsFulfilled(response));
    } catch (error) {
      const resourceError = yield call(
        getResourceError,
        error,
        "POST",
        "PERMISSIONS",
        "Oops. An error occurred when updating the permissions."
      );
      yield put(postPermissionsRejected(resourceError));
    }
  }
}

export function* deletePermissions(action) {
  try {
    yield put(handleLoader(true));
    const { payload } = action.payload;
    const deletePer = yield call(permissionService.deletePermissions, payload);
    yield put(deletePermissionsFulfilled(deletePer));
    yield put(getPermissionsListPending());
    yield "response";
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "DELETE",
      "REMOVE_PERMISSIONS",
      "Oops. An error occurred when deleting the permissions."
    );
    yield put(deletePermissionsRejected(resourceError));
  }
}

export function* postPermissionsActionReset(action) {
  const { payload } = action.payload;
  yield put(postPermissionsFulfilled(payload));
}

export function* deletePermissionsActionReset(action) {
  const { payload } = action.payload;
  yield put(deletePermissionsFulfilled(payload));
}

export function* getEnrollmentStatus(action) {
  try {
    const { payload } = action.payload;
    if (payload) {
      const enrollmentStatus = yield call(
        permissionService.getEnrollmentStatus,
        payload
      );
      yield put(getEnrollmentStatusFulfilled(enrollmentStatus));
    } else {
      yield put(getEnrollmentStatusFulfilled(null));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ENROLLMENT_STATUS",
      "Oops. An error occurred when fetching the enrollment status."
    );
    yield put(getEnrollmentStatusFulfilled(null));
    yield put(getEnrollmentStatusRejected(resourceError));
  }
}

function* watchEntitlementCategories() {
  yield takeEvery(GET_ENTITLEMENT_CATEGORIES_PENDING, entitlementCategories);
}

function* watchPermissionsList() {
  yield takeEvery(GET_PERMISSIONS_LIST_PENDING, permissionsList);
}

function* watchDeletePermissions() {
  yield takeEvery(DELETE_REMOVE_PERMISSIONS_PENDING, deletePermissions);
}

function* watchPostPermissions() {
  yield takeEvery(CREATE_PERMISSIONS_PENDING, postPermissions);
}

function* watchPostPermissionsActionReset() {
  yield takeEvery(
    CREATE_POST_PERMISSIONS_ACTION_RESET_PENDING,
    postPermissionsActionReset
  );
}

function* watchDeletePermissionsActionReset() {
  yield takeEvery(
    DELETE_REMOVE_PERMISSIONS_ACTION_RESET_PENDING,
    deletePermissionsActionReset
  );
}

function* watchExistingEmailLists() {
  yield takeEvery(GET_EXISTING_EMAIL_LISTS_PENDING, existingEmailLists);
}

function* watchEnrollmentStatus() {
  yield takeEvery(GET_ENROLLMENT_STATUS_PENDING, getEnrollmentStatus);
}

export default [
  watchEntitlementCategories,
  watchPermissionsList,
  watchPostPermissions,
  watchPostPermissionsActionReset,
  watchDeletePermissions,
  watchDeletePermissionsActionReset,
  watchExistingEmailLists,
  watchEnrollmentStatus
];
