import { yellow } from "@mui/material/colors";

export const COLORS = {
  SUCCESS: "green",
  Sushi: "#7AA434",
  LightGrey: "#D1D1D1",
  Dodgerblue: "#1E90FF",
  White: "#FFFFFF",
  Apple: "#71AF57",
  SteelBlue: "#528EB6",
  PeriwinkleBlue: "#B3C6EE",
  Terracotta: "#EBB07C",
  Celadon: "#8EC6B6",
  Lavender: " #8A8EB1 ",
  DustyRose: "#D99395",
  PaleYellow: "#FEE0A0",
  CeruleanBlue: "#569DC1",
  PastelPink: "#FBADBA",
  MediumBlue: "#95AFE8",
  SlateBlue: "#6F749F",
  Gray: "#E8E8E8",
  Green: "#478B1A",
  container_bg_color: "#FBFCFC",
  background_color: "#F4F5F5",
  text: "#414141",
  tbl_line_color: "#E8E8E8",
  blue: "#2A6BD2",
  mediumBlue: "#7795D1",
  lightBlue: "#D2DEF5",
  lightGreen: "#B5CD97",
  denimBlue: "#146bd2",
  lightGray: "#e1e2e3",
  red: "#D43136",
  yellow: "#F9C846",
  silver: "#BCBCBC",
  orange: "#ce4225",
  mediumOrange: "#F27C27",
  limeGreen: "#59941B",
  secondary: "#2064B4",
  disabled: "#999999"
};
