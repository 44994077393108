import { call, put, takeEvery } from "redux-saga/effects";
import { CONST_VALUES } from "../../constants/constants";
import * as alertsService from "../../services/alertsService";
import {
  GET_ALERTS_LIST_PENDING,
  getAlertsListFulfilled,
  getAlertsListRejected
} from "../actions/alertsAction";

import getResourceError from "./helpers/getResourceError";

export function* getAlertsList(action) {
  try {
    const { params } = action.payload;
    var site = [];
    if (params?.siteId === CONST_VALUES?.all_sites) {
      params?.user?.map(x => site?.push(String(x?.site_id)));
    } else {
      site?.push(String(params.siteId));
    }
    site =
      "[" +
      String(site)
        .split(",")
        .map(s => `'${s}'`)
        .join(",") +
      "]";
    var product_category_id =
      action.payload &&
      action.payload.params &&
      action.payload.params.product_category_id;
    const alerts = yield call(
      alertsService.alertsList,
      site,
      params,
      product_category_id
    );
    yield put(getAlertsListFulfilled(alerts));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ALERTS_LIST",
      "Oops. An error occurred when fetching the list of assets."
    );
    yield put(getAlertsListRejected(resourceError));
  }
}

function* watchGetAlertsList() {
  yield takeEvery(GET_ALERTS_LIST_PENDING, getAlertsList);
}

export default [watchGetAlertsList];
