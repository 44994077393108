import { css } from "styled-components";
import { breakpoint } from "./helpers/breakpoints";
import { COLORS } from "./helpers/colors";

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export default {
  DashboardMenu: {
    style: css`
      display: grid;
      grid-template-columns:
        1fr minmax(min-content, 120px) minmax(min-content, 120px)
        1fr;
      justify-items: center;
      align-items: center;
      width: 100%;
      margin-top: 15px;

      @media ${breakpoint.sm} {
        grid-template-columns:
          1fr minmax(min-content, 300px) minmax(min-content, 300px)
          1fr;
      }

      @media (min-width: 1120px) {
        display: none;
      }
    `
  },
  DashboardTab: {
    style: css`
      border-top: 3px solid grey;
      color: grey;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      padding: 8px 0px;
      cursor: pointer;

      &.selected {
        border-top: 3px solid black;
        color: black;
      }

      @media ${breakpoint.sm} {
        font-size: 16px;
      }
    `
  },
  ContentWrapper: {
    style: css`
      // margin-top:55px;
      display: grid;
      margin-left: 0px;
      margin-left: ${props => {
        if (!props.showSideNavbar) {
          return "0px";
        } else if (!!props.collapsed) {
          return "64px";
        } else {
          return "220px";
        }
      }};
      @media ${breakpoint.smMax} {
        margin-left: 0px;
      }
    `
  },
  PCAs: {
    PcaWrapper: {
      style: css`
        position: relative;
        background-color: ${p => p.color || "#FBFCFC"};
        text-align: left;
        border-color: ${p => p.borderColor || "white"};

        &.withViewAll {
          padding: 12px;
        }
      `
    }
  },
  KPIs: {
    KpiWrapper: {
      style: css`
        position: relative;
        background-color: white;
        // text-align: center;
        &.withViewAll {
          // padding: 20px 16px 16px 16px;
          width: 100%;
        }
        &.withViewAllForOther {
          // padding: 16px
          width: 100%;
        }
        @media ${breakpoint.minlarge} {
          &.withViewAll {
            // padding: 20px 16px 0px 16px;
            width: 100%;
          }
        }
      `
    },
    KpiMicrocodesItem: {
      style: css`
      display:flex;
      justify-content:start;
      padding: 4px 0px;
      & .alert-icon {
        position:relative;
        top:3px;
        fill: ${p => p.color};
      }
      & .mc-block{
         display:flex;
         justify-content:space-between;
         width:100%; 
      }
      & .kpiMicrocodeTitle {
        color: #414141;
        font-size:20px;
        width:45%
        text-align:left;
        margin-left:2px;
      }
      & .kpiAlertTitle {
        color: #414141;
        font-size:24px;
        line-height: 32px;
        font-weight: 600;
        text-align:left;
        margin-left:2px;
        margin: auto 0;
      }
      & .kpiMicrocodeStatus{
        margin: auto 0;
      }
    `
    },
    KpiStatusItem: {
      style: css`
        display: flex;
        justify-content: start;
        & svg {
          margin-top: 5px;
          fill: ${p => p.color};
        }
        & img {
          margin-top: 5px;
        }
        & .kpiAlertTitle {
          color: #414141;
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          text-align: left;
          margin-left: 6px;
          color: #414141;
        }
      `
    },
    AlertSummary: {
      style: css`
      
      @media ${breakpoint.xl} {
      display: flex ;
      flex-direction: column ;
      align-items: flex-start ;
      justify-content: flex-start ;
      gap: 10px ; 
      }
      // @media ${breakpoint.l}and ${breakpoint.fullLarge}{
      //   display: flex;
      //   flex-direction: column;
      //   align-items: flex-start;
      //  justify-content: flex-start;
      //  gap:0px
      // }
      // @media ${breakpoint.ml}{
      //   display: flex;
      //   flex-direction: column;
      //   align-items: flex-start;
      //  justify-content: flex-start;
      //  gap:0px
      // }
      
      `
    },
    ViewAllWrapper: {
      style: css`
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px;
        border-top: 2px solid #f0f0f0;
        text-align: right;
        cursor: pointer;
      `
    },
    Graph: {
      style: css`
        background-color: ${p => p.color};
        height: 140px;
        width: 100px;
        position: relative;
        height: 24px;
      `
    },
    GraphBig: {
      style: css`
        background-color: ${p => p.color};
        height: 24px;
        //display: flex;
      `
    },
    Graph1: {
      style: css`
        background-color: white;
        height: 140px;
        width: 100px;
        position: relative;
      `
    },
    Graph2: {
      style: css`
        background-color: ${p => p.color};
        height: 140px;
        width: 100px;
        position: relative;
      `
    },
    GraphBig2: {
      style: css`
        background-color: ${p => p.color};
        height: 20px;
        // width: 490px;
        position: relative;
        display: flex;
      `
    },
    GraphBigForFile: {
      style: css`
        background-color: ${p => p.color};
        height: 20px;
        position: relative;
        display: flex;
      `
    },
    GraphFilled1: {
      style: css`
        background-color: #dedede;
        height: ${p => p.percent}%;
        width: 100%;
        position: absolute;
        bottom: ${p => p.percent_bottom}%;
      `
    },
    GraphFilled2: {
      style: css`
        background-color: white;
        height: calc(140px - ${p => p.percent_bottom}%);
        width: 100%;
        position: absolute;
        bottom: ${p => p.percent_bottom}%;
        border-left: 1px solid #dedede;
        border-right: 1px solid #dedede;
        border-top: 1px solid #dedede;
      `
    },
    GraphFilledForSmall1: {
      style: css`
        background-color: ${p => p.color};
        // height: ${p => p.percent}%;
        width: ${p => p.percent}%;
        //position: absolute;
        ${p => (p.position === "top" ? "top: 0px;" : "bottom: 0px;")}
      `
    },
    GraphFilled: {
      style: css`
        background-color: ${p => p.color};
        height: ${p => p.percent}%;
        width: 100%;
        //position: absolute;
        ${p => (p.position === "top" ? "top: 0px;" : "bottom: 0px;")}
      `
    },
    GraphFilledForSmall: {
      style: css`
        background-color: ${p => p.color};
        // height: ${p => p.percent}%;
        width:  ${p => p.percent}%
        //position: absolute;
        ${p => (p.position === "top" ? "top: 0px;" : "bottom: 0px;")}
      `
    },
    UsedLegend: {
      style: css`
        background-color: ${p => p.color};
        // height:16px
        // width: 16px;
        height: 0.6rem;
        width: 0.6rem;
        border-radius: 0.6rem
        margin-right:10px
        margin-bottom:23px
      `
    },
    AvailableLegend: {
      style: css`
        background-color: ${p => p.color};
        // height:16px
        // width: 16px;
        height: 0.6rem;
        width: 0.6rem;
        border-radius: 0.6rem
        margin-right:10px
        margin-bottom:23px
      `
    },
    FreeTitle: {
      style: css`
        &&,
        && + span {
          font-size: 24px;
        }
      `
    },
    PercentText: {
      style: css`
        color: #414141;
        position: absolute;
        bottom: -21px;
        left: ${p => p.percent}%;
      `
    },
    PercentTextForLarge: {
      style: css`
        color: #414141;
        position: absolute;
        bottom: ${p => p.percent}%;
      `
    },
    PercentTitle: {
      style: css`
        && {
          color: ${p => p.color};
        }
      `
    },
    HostCapacity: {
      FreeGrid: {
        style: css`
          display: grid;
          grid-template-columns: 1fr;
          justify-items: left;
          margin-top: 15px;
          margin-bottom: 15px;
          white-space: nowrap;

          & > div {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-gap: 20px;
            justify-items: left;
            width: 100%;
          }
        `
      },
      GraphsWrapper: {
        style: css`
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: auto;
          grid-template-columns: auto;
          justify-items: baseline;
        `
      },
      GraphWrapper: {
        style: css`
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 1fr auto;
          grid-template-columns: 1fr auto;
          justify-items: center;
          position: relative;
          // padding-right: 30px;
          left: 15px;
        `
      },
      GraphFilledText: {
        style: css`
          && {
            color: white;
          }
        `
      },
      GraphOuterText: {
        style: css`
          position: absolute;
          left: 0;
          right: 0;
          bottom: ${p => p.bottom}px;
        `
      },
      PercentTextWrapper: {
        style: css`
          position: absolute;
          //height: 140px;
          bottom: -5px;
          // right: 25px;
          white-space: nowrap;
        `
      },
      UsedTotalGrid: {
        style: css`
          display: -ms-grid
          display: grid;
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          justify-items: left;
          text-align: left;
        `
      },
      VerticalLine: {
        style: css`
          width: 2px;
          background-color: #dedede;
        `
      },
      VolumesTitle: {
        style: css`
          && {
            white-space: nowrap;
            font-size: 24px;
          }
        `
      }
    },
    StorageCapacity: {
      GraphsWrapper: {
        style: css`
          display:-ms-grid
          display: grid;
          -ms-grid-columns: auto 1fr auto;
          grid-template-columns: auto 1fr auto;
          justify-items: baseline;
          margin-top: 20px;
          // padding-bottom: 20px;
         // border-bottom: 1px solid #dedede;
          @media ${breakpoint.fullLarge}{
            grid-template-columns: auto;
          }
        `
      },
      GraphWrapper: {
        style: css`
          display:-ms-grid
          display: grid;
          -ms-grid-columns: 1fr auto;
          grid-template-columns: 1fr auto;
          justify-items: center;
          position: relative;
          //padding-right: 30px;
        `
      },
      PercentMarker: {
        style: css`
          background-color: #414141;
          height: 20px;
          width: 1px;
          position: absolute;
          left: ${p => p.percent}%;
          z-index: 5;
        `
      },
      PercentMarkerForLarge: {
        style: css`
          background-color: #414141;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: ${p => p.percent}%;
          z-index: 5;
        `
      },
      PercentTextWrapper: {
        style: css`
          position: absolute;
          height: 140px;
          bottom: -7px;
          right: -7px;
        `
      },
      PercentTextWrapper1: {
        style: css`
          //position: absolute;
          //height: 140px;
          bottom: -5px;
          //right: 25px;
         // left: ${p => p.percent}%;
        `
      },

      FreeGrid: {
        style: css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-items: left;
          //margin-top: 12px;
          margin-bottom: 6px;
          // white-space: nowrap;
          width: 100%;
          @media ${breakpoint.l} {
            white-space: nowrap;
          }
        `
      },
      UsedTotalGrid: {
        style: css`
          display:-ms-grid
          display: grid;
          grid-template-columns: auto auto auto auto;
          -ms-grid-columns: 1fr auto 1fr
          justify-items: left;
        `
      }
    },
    ParityGroupCapacity: {
      ParityDrives: {
        style: css`
			  background-color: ${p => p.color};
			  // height:16px
			  // width: 16px;
        height: 0.6rem;
        width: 0.6rem;
        border-radius: 0.6rem

			  margin-right:10px
			`
      }
    }
  },
  MainContent: {
    style: css`
      height: ${p => "calc(100vh - 64px)"};
      ${p => p.isLogin && "height: calc(100vh - 90px)"};
      overflow: auto;
      padding: ${p => (p.hideContentPadding ? "0px" : "10px 15px 15px 15px")};
      background-color: ${COLORS.background_color};
      @media ${breakpoint.sm} {
        padding: ${p => (p.hideContentPadding ? "0px" : "32px")};
        height: ${p => "calc(100vh - 64px)"};
      }
      @media ${breakpoint.mw} {
        height: ${p => "calc(100vh - 0px)"};
        overflow: ${props => (props.isSidebarOpen ? "hidden" : "auto")};
      }
      @media (min-width: 1120px) {
        padding: ${p => (p.hideContentPadding ? "0px" : "32px")};
        height: ${props =>
          props.isMarquee ? "calc(100vh - 128px)" : "calc(100vh - 64px)"};
      }
    `
  },
  GraphsWrapper: {
    style: css`
      background-color: white;
      //padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      & > div {
        width: 100%;

        canvas {
          max-width: 100%;
        }
      }

      @media ${breakpoint.sm} {
        & > div {
          padding: 20px;
          width: 70%;
        }
      }

      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [3];
        grid-template-columns: repeat(3, 1fr);
      }
    `
  },
  GraphFilters: {
    style: css`
      border-bottom: 1px solid lightgrey;
      background-color: white;
      padding: 20px 25px 35px 25px;
      margin: 0px -20px 20px -20px;
    `
  },
  MetricCardsGrid: {
    style: css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      justify-items: center;
      margin-bottom: 60px;

      div {
        display: grid;
        width: 100%;
      }
    `
  },
  MetricCardWrapper: {
    style: css`
      border-top: 2px solid #2cb3e0;
      background-color: white;
      padding: 25px;
    `
  },
  Metric: {
    style: css`
      && {
        display: block;
        font-size: 18px;
        white-space: nowrap;
      }

      @media ${breakpoint.sm} {
        && {
          font-size: 32px;
        }
      }
    `
  },
  KpisGrid: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;
      margin-bottom: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
        margin-bottom: 24px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        width: 50%;
      }
    `
  },
  GridContainer: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;
      margin-bottom: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
        margin-bottom: 32px;
      }

      // @media ${breakpoint.m} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-columns: repeat(2, 1fr);
      //   grid-gap: 30px;
      // }
      @media ${breakpoint.mMax} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        // grid-template-columns: 49.3% 49.3%;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    `
  },
  GridContainerForLarge: {
    style: css`
      display: -ms-grid;
      display: grid;

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: 49.3% 49.3%;
        // grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
    `
  },

  BlockStorageGrid: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
      }
    `
  },

  OverviewGridContainer: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;
      margin-bottom: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
        margin-bottom: 32px;
      }

      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
        margin-bottom: 32px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(1, 1fr);
      }
    `
  },
  OverviewGridContainerForLarge: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: 49.3% 49.3%;
     

      grid-gap: 32px;
      margin-bottom: 32px;

      // @media ${breakpoint.sm} {
      //   -ms-grid-columns: (1fr) [1];
      //   grid-template-columns: repeat(1, 1fr);
      //   grid-gap: 30px;
      //   margin-bottom: 24px;
      // }

      // @media ${breakpoint.m} {
      //   -ms-grid-columns: (1fr) [1];
      //   grid-template-columns: repeat(1, 1fr);
      //   grid-gap: 30px;
      //   margin-bottom: 24px;
      // }

      // @media ${breakpoint.l} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-columns: repeat(1, 1fr);
      // }
    `
  },
  OverviewGridContainerFile: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      margin-bottom: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
        margin-bottom: 32px;
      }

      @media ${breakpoint.m} {
        // -ms-grid-columns: (1fr) [1];
        // grid-template-columns: repeat(1, 1fr);
        // grid-gap: 30px;
        // margin-bottom: 24px;
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(1, 1fr);
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(1, 1fr);
      }
    `
  },
  OverviewFirstGrid: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(4, 1fr);
      }
    `
  },
  OverviewFirstGridForLarge: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
     

      // @media ${breakpoint.sm} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-columns: repeat(2, 1fr);
      //   grid-gap: 30px;
      // }
      // @media ${breakpoint.m} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-columns: repeat(2, 1fr);
      //   grid-gap: 30px;
      // }

      @media ${breakpoint.xl} {
        // -ms-grid-columns: (1fr) [2];
        // grid-template-columns: repeat(4, 1fr);

      }
    `
  },
  OverviewFirstGridFile: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(3, 1fr);
      }
    `
  },

  MetricLarge: {
    style: css`
      font-size: 50px;
      margin-bottom: 8px;
    `
  },
  MetricTitle: {
    style: css`
      width: 100%;
      font-weight: 600;
      font-size: 18px;
    `
  },
  ProgressBarTitle: {
    style: css`
      display: grid;
      width: 70%;
      grid-template-columns: auto 1fr auto;
      padding: 0px 5px;
    `
  },
  RenewalNotices: {
    style: css`
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;

      div {
        justify-items: left;
        position: relative;
        left: -40px;
        top: 10px;
      }
    `
  },
  RenewalNotice: {
    style: css`
      font-size: 22px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      span {
        margin-right: 8px;
        color: #e68c18;
      }

      &:last-child span {
        color: #ff0000;
      }
    `
  },
  RiskScore: {
    style: css`
      font-size: 90px;
      margin-bottom: 10px;
    `
  },
  AnalyticsGraphsWrapper: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          padding: 19px;
          width: 100%;
        }
      }
      // @media ${breakpoint.m} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-columns: repeat(2, 1fr);
      //   & > div {
      //     width: 100%;
      //     margin-left: 0;
      //   }
      // }
      @media ${breakpoint.mMax} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(1, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }
      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },

  AnalyticsGraphsWrapperFile: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          padding: 19px;
          width: 100%;
        }
      }
      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        // grid-template-columns: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }
      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        width:207%
        grid-template-columns: repeat(1, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },
  AnalyticsGraphsWrapperFileMicro: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          padding: 19px;
          width: 100%;
        }
      }
      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        grid-template-rows: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }
      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        grid-template-rows: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },

  AnalyticsGraphsWrapperNew: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      grid-template-columns:35% 1fr 1fr;
      grid-gap: 32px;
      & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.xm} {
        grid-template-columns:100%;
        & > div {
          padding: 20px;
          width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        grid-template-columns:100%;
        & > div {
          padding: 20px;
          width: 100%;
        }
      }
      @media ${breakpoint.m} {
        grid-template-columns:100%;
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }
      @media ${breakpoint.l} {
        grid-template-columns:35%  1fr 1fr;
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },
  AnalyticsGraphsWrapperNewForLarge: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
      margin-top:30px
      margin-top: 20px & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      
      @media ${breakpoint.xl} {
        grid-template-columns: 49.3% 49.3%
        margin-top: 32px
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },

  // AnalyticsInternalDirveGraphsWrapper: {
  //   style: css`
  //     padding: 5px;
  //     width: 100%;
  //     display: -ms-grid;
  //     display: grid;
  //     -ms-grid-columns: 1fr;
  //     grid-template-columns: repeat(1, 1fr);
  //     grid-gap: 30px;
  //     & > div {
  //       width: 100%;
  //       canvas {
  //         max-width: 100%;
  //       }
  //     }
  //     @media ${breakpoint.sm} {
  //       & > div {
  //         padding: 20px;
  //         width: 100%;
  //       }
  //     }
  //     @media ${breakpoint.m} {
  //       -ms-grid-columns: (1fr) [2];
  //       grid-template-columns: repeat(2, 1fr);
  //       & > div {
  //         width: 100%;
  //         margin-left: 0;
  //       }
  //     }
  //     @media ${breakpoint.l} {
  //       -ms-grid-columns: (1fr) [2];
  //       grid-template-columns: 35%;
  //       & > div {
  //         width: 100%;
  //         margin-left: 0;
  //       }
  //   `
  // },

  StorageGrid: {
    style: css`
      //padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      margin-top: 30px;
      & > div {
        width: 100%;
        background-color: white;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          padding: 20px;
          width: 100%;
        }
      }

      // @media ${breakpoint.m} {
      //   width: 100%;
      //   margin-top: 0;
      // }
      // @media ${breakpoint.mMax} {
      //   width: 100%;
      //   margin-top: 0;
      //   // display:flex
      //   }

      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        margin-top:0px;
        margin-left:30px;
        width: 31%;
        & > div {
          width: 100%;
          margin-left: 0;
        }
        `
  },

  StorageGridOverview: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;
      // margin-top: 30px;
      & > div {
        width: 100%;
        background-color: white;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          padding: 20px;
          width: 100%;
        }
      }

      @media ${breakpoint.m} {
      width: 100%;
      margin-top: 0;
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        margin-top:0px;
        // margin-left:30px;
        width: 100%;
        & > div {
          width: 100%;
          margin-left: 0;
        }
        `
  },
  StorageGridOverviewForLarge: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
      //margin-top: 30px;
      & > div {
        width: 100%;
        background-color: white;
        canvas {
          max-width: 100%;
        }
      }
     

    

      // @media ${breakpoint.l} {
      //   -ms-grid-columns: (1fr) [1];
      //   grid-template-columns: repeat(1, 1fr);
      //   margin-top:0px;
      //   // margin-left:30px;
      //   width: 100%;
      //   & > div {
      //     width: 100%;
      //     margin-left: 0;
      //   }
        `
  },
  AssetOverviewForLarge: {
    style: css`
      // padding: 5px;
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      //margin-top: 30px;
      & > div {
        width: 100%;
        background-color: white;
        canvas {
          max-width: 100%;
        }
      }
     

    

      // @media ${breakpoint.l} {
      //   -ms-grid-columns: (1fr) [1];
      //   grid-template-columns: repeat(1, 1fr);
      //   margin-top:0px;
      //   // margin-left:30px;
      //   width: 100%;
      //   & > div {
      //     width: 100%;
      //     margin-left: 0;
      //   }
        `
  },
  TemoAndHardwareGraphWrapper: {
    style: css`
      display: block;
      // @media ${breakpoint.m} {
      //   display: flex;
      //   gap: 30px;
      // }
      @media ${breakpoint.mMax} {
        display: flex;
        // gap: 30px;
        flex-wrap:wrap
      }
      @media ${breakpoint.l} {
        display: flex;
        gap: 0;
      }
    `
  },

  TemoAndHardwareGraphWrapperFile: {
    style: css`
      display: grid;
      gap: 30px;
      // @media ${breakpoint.m} {
      //   display: flex;
      //   gap: 30px;
      // }
      @media ${breakpoint.mMax} {
        display: flex;
        flex-wrap:wrap;
        gap: 30px;
      }
      @media ${breakpoint.l} {
        display: flex;
        gap: 32px;
      }
    `
  },
  TemoAndHardwareGraphWrapperNew: {
    style: css`
      display: block;
      grid-template-columns: repeat(1, 1fr);
      // @media ${breakpoint.m} {
      //   display: flex;
      //   gap: 30px;
      // }
      // @media ${breakpoint.l} {
      //   display: flex;
      //   gap: 0;
      // }
    `
  },
  MicrocodeContentWrapper: {
    style: css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      @media ${breakpoint.smMax} {
        grid-template-columns: 1fr;
      }
    `
  }
};
